$enable-gradients: true;

@import "bootswatch/theme/variables";
@import "~bootstrap/scss/bootstrap";
@import "bootswatch/theme/bootswatch";
@import 'react-datepicker/dist/react-datepicker.css';
@import 'assets/scss/text.scss';
@import 'assets/scss/colors.scss';
@import 'assets/scss/tinymce.styles.scss';
@import 'assets/scss/dashboard.scss';

html {
  font-size: 16px;
}

.scrollable {
  overflow-y: auto;
}

body {
  background-color: $background;
}

.cursor-disabled {
  cursor: not-allowed;
}

.disabled {
  background-color: $secondary;
  opacity: 1;
  cursor: not-allowed;
}

/* making it a flex row, makes the elements have the same height */
.row-eq-col-height {
  display: flex;
  flex-flow: row wrap;
}

/* making children 1-1 grids, fills the col- elements */
.row-eq-col-height > [class*='col-'] {
  display: -ms-grid;
  display: grid;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
    outline: 0;
}

.react-datepicker__month-container {
  width: 270px;
  height: 300px;
}

// fix tiny editor going over datepicker popover
.react-datepicker-popper {
    z-index: 9999;
}

.react-datepicker__day-names, .react-datepicker__week {
  display: flex;
  justify-content: space-evenly;
}

.wrapper {
  position: absolute;
  left: 240px;
  width: -webkit-fill-available;
  width: -moz-available;
}

.navbar-container {
  max-width: none !important;
  max-height: none !important;
  overflow-y: initial !important;
  margin-right: 4.6rem !important;
  margin-left: 4.6rem !important;
  left: 0 !important;
}

.navbar-brand {
  padding-left: 0;
}

.line-solid-full {
  margin: 20px 0 10px 0;
  border: 0;
  border-top: 1px solid #d9dadc;
}

.fa-stack {
  display: inline-block;
  line-height: 1rem;
  position: relative;
  vertical-align: middle;
  font-size: 1rem;
}

.app-fs-lg {
  font-size: 1.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

.app-fs-table-header {
  font-size: 1.05rem;
}

// class used in the Table component th
.app-table-th {
  font-size: 14px;
  // you can have custom height if you so desire through the table opts.headerClass
  height: 95px;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #999999;
}

.app-fs-heading {
  font-size: 1.05rem;
}

.cursor-pointer {
  cursor: pointer;
}

.toast {
  position: fixed;
  z-index: 100;
}

.fs-1rem {
  font-size: 1rem;
}

.ClaimPreview-Page .react-pdf__Page__canvas {
  margin: 0 auto;
}

.table-scrollable-x {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.claim-state-table {
  td {
    background-color: $white;
  }
}

.nested-table-row {
  border-left: 1px solid $gray-500;
  td {
    background-color: $white;
  }
}

.label-round-xl {
  line-height: 1rem;
  display: inline-block;
  padding: 1.5em;
  border-radius: 50%;
  text-align: center;
}
.solid-border {
  border: 4px solid #00aeef !important;
}
.label-info {
  background-color: #00AEEF;
}

.fit-content {
  min-width: fit-content;
}

.break-text {
  word-break: break-word;
  white-space: break-spaces;
}

.tooltip-wide {
  min-width: 500px;
  font-size: 1rem;;

  .tooltip-inner {
    min-width: 100%;
  }
}

.carousel-control-prev,
.carousel-control-next {
  background: transparent;
  color: $primary;
  width: unset;
}

.carousel-control-prev:hover {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001))
}

.carousel-control-next:hover {
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001))
}

.sticky {
  position: sticky;
  top: 0;
  left: 0;
  background-color: white; // has to have some color, and should be overwritten if needed
}

.text-center {
  text-align: center;
}

.text-black {
  color: black;
}

@media only screen and (min-width: 1200px) {
  .modal-xl {
    max-width: 1550px;
  }
}

.modal-header-custom-sm {
  background: url("./assets/images/ayming-logo-without-text.svg") right 185%/25% no-repeat, linear-gradient(90deg, #0078B9 10%, #00aeef 85%);
  width: 100%;
  height: 54px;
}

.modal-header-custom-lg {
  background: url("./assets/images/ayming-logo-without-text.svg") right 115%/22% no-repeat, linear-gradient(90deg, #0078B9 10%, #00aeef 85%);
  width: 100%;
  height: 84px;
}

.modal-header-custom-xl {
  background: url("./assets/images/ayming-logo-without-text.svg") right 115%/12% no-repeat, linear-gradient(90deg, #0078B9 10%, #00aeef 85%);
  width: 100%;
  height: 84px;
}

.tox-tinymce {
  min-height: 500px;
}

.project-slice-box { 
  padding: 0px !important;
}

/*
  This is done because the project slice box loads a bootstrap card element
  but inside it - it loads the track changes editor with comments.
  The comments at the moment appear as a separate box with position absolute.
*/
.project-slice-box .card {
  overflow: initial;
}

.td-center { 
  text-align: 'center';
  vertical-align: 'middle';
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loader-spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 2px solid $primary;
  animation: spinner-bulqg1 0.96s infinite linear alternate,
       spinner-oaa3wk 1.92s infinite linear;
}

@keyframes spinner-bulqg1 {
  0% {
     clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
     clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
  }

  25% {
     clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
  }

  50% {
     clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  62.5% {
     clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  75% {
     clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
  }

  100% {
     clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
  }
}

@keyframes spinner-oaa3wk {
  0% {
     transform: scaleY(1) rotate(0deg);
  }

  49.99% {
     transform: scaleY(1) rotate(135deg);
  }

  50% {
     transform: scaleY(-1) rotate(0deg);
  }

  100% {
     transform: scaleY(-1) rotate(-135deg);
  }
}

@keyframes loader-animation {
  0% {
    left: -100%;
  }
  49% {
    left: 100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}
.horizontal-loader {
  height: 5px;
  width: 100%;
}
.horizontal-loader .bar {
  width: 100%;
  position: absolute;
  height: 3px;
  background-color: $primary;
  animation-name: loader-animation;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

%custom-firefox-scrollbar {
  scrollbar-color: #8B8B8B $disabled; /* thumb and track color */
  scrollbar-width: thin;
  border-radius: 10px !important;
}

.status-container {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.status-dot {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  margin-right: 8px;

  &-info {
    background-color: $primary;
  }
  &-disabled {
    background-color: $disabled;
  }
  &-success {
    background-color: $success-color;
  }
  &-warning {
    background-color: $warning-color;
  }
  &-error {
    background-color: $error;
  }
  &-important {
    background-color: $important;
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background-color: $disabled;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #666e7e;
  border-radius: 10px;
}

.custom-datepicker-input {
  background: url("assets/images/calendar-icon.svg") $white no-repeat 97%;
  background-size: 20px;
  min-width: 100px;
}

.rc-steps-item-finish .rc-steps-item-icon {
  background: #e8f3f9 !important;
  border-radius: 40px !important;
  border: 2px solid $primary !important;
}

.rc-steps-item-finish .rc-steps-item-icon > .rc-steps-icon {
  top: -10px;
}

.rc-steps-icon rcicon rcicon-check {
  color: $primary !important;
}

.rc-steps-item-process .rc-steps-item-icon {
  background: $primary !important;
  border-radius: 40px !important;
  border: 2px solid $primary !important;
}

.rc-steps-item-process .rc-steps-icon {
  color: $white !important;
}

.rc-steps-icon {
  color: $primary !important;
}

.rc-steps-item-icon {
  width: 42px !important;
  height: 42px !important;
  line-height: 42px !important;
}

.rc-steps-item-wait .rc-steps-item-icon {
  background: #e8f3f9 !important;
  border-radius: 40px !important;
  border: 2px solid $primary !important;
}

.rc-steps-vertical .rc-steps-item-content {
  min-height: 68px !important;
}

.rc-steps-vertical .rc-steps-item-tail {
  left: 20px !important;
  top: 12px !important;
}

.rc-steps-item-title {
  color: $primary !important;
  font-weight: 100 !important;
}

.rc-steps-item-description {
  color: $primary !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.rc-steps-vertical .rc-steps-item-title {
  line-height: 32px !important;
}

.persona-container {
  display: flex;
  margin-right: 1rem;

  .persona-image {
    width: 40px;
    height: 40px;
    background-color: $primary;
    color: $white;
    font-weight: 600;
    border-radius: 50%;
    margin-right: 0.7rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden; 
  }

  .persona-image-white {
    background-color: $white !important;
    color: $primary !important;
  } 

  .persona-name-block {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
  }

  .persona-text-white {
    color: $white !important;
  }

  .persona-name {
    font-weight: 400;
    font-size: 16px;
  }

  .persona-description {
    font-weight: 400;
    font-size: 14px;
    color: $primary;
  }
}

.ps-menu-button:hover {
  background-color: transparent !important;
}

.filters-menu {
  background-color: $gray-110;
  border: none;
  padding-left: 8px;
  inset: 35px 0px auto auto !important;
  transform: translate3d(0px, 0px, 0px) !important;
}

.accordition-icon {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  padding-top: 2px;
  border-radius: 3px;
  background-color: $primary;
  color: $white;
}

.custom-card-header {
  background: linear-gradient(90deg, #0078B9 10%, #00aeef 85%);
}

.custom-card-header-logo {
  background: url("./assets/images/ayming-logo-without-text.svg") right 115%/22% no-repeat, linear-gradient(90deg, #0078B9 10%, #00aeef 85%);
}

.company-cards-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
}

.company-card-header {
  background: url("./assets/images/ayming-logo-without-text.svg") right 115%/22% no-repeat, linear-gradient(90deg, #0078B9 10%, #00aeef 85%);
  background-position: 103% 92%;
  height: 2rem;
}

.company-card {
  width: 20rem;
  height: 10rem;
}

.company-card-body {
  background: linear-gradient(90deg, #0078B9 10%, #00aeef 85%);
  border-radius: 0 0 0.25rem 0.25rem;
  color: $white;
  font-weight: 400;
  padding: 0.5rem;

  .company-card-name {
    font-size: 20px;
    font-weight: 400;
  }

  .company-card-control {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
}

.claim-task-status-badge {
  border: $primary-400 3px solid;
  border-radius: 32px;
  width: 40px;
  height: 40px;
  color: $primary-400;
  margin-left: auto;
  margin-right: auto;
}

.claim-group-list-title {
  color: $secondary-color-grey;
}

.claim-group-list-card {
  color: $secondary-color-grey;
  font-size: 14px;
  font-weight: 600;
}

.claim-group-list-view-button {
  background-color: $primary-400;
  color: $white;
  border-color: $primary-400;
}

.claim-group-list-card .projects-block {
  border-left: $secondary-color-grey 2px solid;
}

.claim-group-list-card .costs-block {
  border-right: $secondary-color-grey 2px solid;
}

.center-relative {
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
#root { 
  height: 100vh;
}

.financial_headings { 
  min-width: 70pt;
  max-width: 70pt;
  white-space: break-spaces;
}

.cost_categories_subpage_header {
  width: 528px;
  overflow-x: auto;
  padding-bottom: 5px;
}

.cost_categories_subpage_header_cell {
  min-width: 66px;
}

.cost_categories_subpage_header_cell_container {
  min-width: 264px;
  justify-content: space-evenly;
  border-left: 1px solid #eee !important;
}

.cost_categories_subpage_row_cell {
  width: 528px;
  overflow-x: hidden;
}

.cost_categories_subpage_row_cell_container {
  min-width: 264px;
  padding: 5px;
  border-radius: 3px;
}

.width_comparison_contanier {
  width: auto;
  display: inline-block;
  visibility: hidden;
  position: fixed;
  overflow: auto;
}

.width_comparison_contanier.sm {
  font-size: 0.765625rem;
}

.width_comparison_contanier.md {
  font-size: 0.875rem;
}

.width_comparison_contanier.lg {
  font-size: 1.09375rem;
}

/* Rules for sizing the google-material icons */
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.material-icons.error { color: $error; }
.material-icons.warning { color: $warning; }
.material-icons.success { color: $success; }
.material-icons.info { color: $info; }
.material-icons.blue { color: $blue; }

.material-icons {
  vertical-align: bottom;
}
